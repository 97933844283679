import {Component, OnInit} from "@angular/core";
import {ThemeService} from "@theme/theme.service";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-auth-layout",
  templateUrl: "./auth-layout.component.html",
  styleUrls: ["./auth-layout.component.scss"],
})
export class AuthLayoutComponent implements OnInit {
  constructor(public themeService: ThemeService) {
    themeService.refreshTheme();
  }

  ngOnInit() {}
}
