import {ErrorHandler, Injectable} from "@angular/core";
import {environment} from "@environments/environment";
import {CloudWatchLogsClient, AssociateKmsKeyCommand} from "@aws-sdk/client-cloudwatch-logs";
// TODO: get credentials and configure logging
// https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-cloudwatch-logs/index.html

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  client: CloudWatchLogsClient;
  command: AssociateKmsKeyCommand;

  constructor() {
    if (this.client == null)
      if (environment.cloudWatchKeyArn) {
        this.client = new CloudWatchLogsClient({
          region: "us-east-1",
        });
        const params = {
          logGroupName: "Web",
          kmsKeyId: environment.cloudWatchKeyArn,
        };
        this.command = new AssociateKmsKeyCommand(params);
      }
  }

  async handleError(error) {
    console.error(error);

    if (this.client !== null) {
      try {
        await this.client.send(this.command, error);
      } catch (error) {
        console.log("failed to send error event");
      } finally {
        // finally.
      }
      return;
    }

    if (environment.cloudWatchKeyArn) {
      console.warn("CloudWatch is not configured!");
    }
  }
}
