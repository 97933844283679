import {AfterViewInit, Component, OnInit, Renderer2} from "@angular/core";
import {ThemeService} from "@theme/theme.service";
import {OverlayContainer} from "@angular/cdk/overlay";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {MatIconRegistry} from "@angular/material/icon";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DomSanitizer, Title} from "@angular/platform-browser";
import {CUSTOM_ICONS} from "./icons";
import {ProfileService} from "@channel/profile.service";
import {UserService} from "@user/user.service";
import moment from "moment-timezone";
import {User} from "@user/user.interface";
import {NotificationService} from "@notifications/notification.service";
import {Notification, NotificationGroup} from "@notifications/notification.interface";
import {AuthenticationService} from "@auth/authentication.service";
import {StreamService} from "@shared/stream/stream.service";
import {FeedService} from "@shared/feed/feed.service";
import {ActivityService} from "@shared/activity/activity.service";
import {SourceService} from "@shared/channel/source.service";
import {InvoiceService} from "@shared/subscription/invoice.service";
import {SubscriptionService} from "@shared/subscription/subscription.service";
import {AnalyticsService} from "@shared/utils/analytics-service/analytics.service";
import {filter, map, tap} from "rxjs/operators";
import {SidenavService} from "@layout/sidenav/sidenav.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {AsyncMessageService} from "@shared/utils/async-message.service";
import {TeamService} from "@shared/user/team.service";

@UntilDestroy()
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit {
  title: string;

  constructor(
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private profileService: ProfileService,
    private userService: UserService,
    private notificationService: NotificationService,
    private snackBar: MatSnackBar,
    public themeService: ThemeService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private overlayContainer: OverlayContainer,
    private renderer: Renderer2,
    private streamService: StreamService,
    private feedService: FeedService,
    private activityService: ActivityService,
    private sourceService: SourceService,
    private invoiceService: InvoiceService,
    private subscriptionService: SubscriptionService,
    private sidenavService: SidenavService,
    private analyticsService: AnalyticsService,
    private asyncMessageService: AsyncMessageService,
    private teamService: TeamService,
  ) {}

  ngOnInit() {
    this.trackPageChangeAndSetPageTitle();
    this.analyticsService.initialize();
    this.setCustomIcons();
    this.themeService.isDarkTheme.pipe(untilDestroyed(this)).subscribe((isDarkTheme) => {
      if (isDarkTheme) {
        this.overlayContainer.getContainerElement().classList.add("dark");
        this.renderer.addClass(document.body, "dark");
      } else {
        this.overlayContainer.getContainerElement().classList.remove("dark");
        this.renderer.removeClass(document.body, "dark");
      }
    });
    this.authenticationService.onUserLoggedIn.subscribe((user) => {
      if (user == null) return;
      this.asyncMessageService.init();
    });

    this.authenticationService.onUserLogOut.subscribe(() => {
      this.asyncMessageService.disconnect();
    });

    this.authenticationService.currentUser.pipe(untilDestroyed(this)).subscribe((user: User) => {
      if (user == null) {
        return;
      }
      if (user.Token == null) {
        return;
      }
      this.getDefaultProfiles();
      this.setTimezone();
      this.initNotifications();
    });
    this.authenticationService.onUserLogOut.pipe(untilDestroyed(this)).subscribe(() => this.clearCache());
  }

  private trackPageChangeAndSetPageTitle() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        tap(() => (this.sidenavService.isDrawerOpen = false)),
      )
      .pipe(untilDestroyed(this))
      .subscribe((activatedRoute: ActivatedRoute) => {
        const parent = AppComponent.findRouteWithTitle(activatedRoute);
        if (parent) {
          this.title = parent.snapshot.data["title"];
          if (parent.firstChild && parent.firstChild.firstChild) {
            const current = AppComponent.findRouteWithTitle(parent.firstChild, this.title);
            if (current) {
              this.title = current.snapshot.data["title"] + " | " + this.title;
            }
          }
          const notificationCount = this.notificationService.count;
          this.title = this.title + " | SocialWeaver";
          if (notificationCount > 0) {
            this.titleService.setTitle(`(${notificationCount}) ${this.title}`);
          } else {
            this.titleService.setTitle(`${this.title}`);
          }
        }
      });
  }

  private static findRouteWithTitle(route: ActivatedRoute, parentTitle = ""): ActivatedRoute {
    while (route && (route.snapshot.data["title"] === parentTitle || !route.snapshot.data["title"])) {
      route = route.firstChild;
    }
    return route;
  }

  clearCache() {
    this.streamService.clearCache();
    this.feedService.clearCache();
    this.activityService.clearCache();
    this.userService.clearCache();
    this.profileService.clearCache();
    this.sourceService.clearCache();
    this.invoiceService.clearCache();
    this.subscriptionService.clearCache();
    this.teamService.clearCache();
  }

  private initNotifications() {
    this.notificationService.init();
    this.notificationService
      .onRaiseNotification()
      .pipe(untilDestroyed(this))
      .subscribe((notificationGroup: NotificationGroup) => {
        notificationGroup.Notifications.forEach((notification: Notification) => {
          this.snackBar
            .open(notification.Title, null, {
              duration: 5000,
              verticalPosition: "top",
            })
            .onAction()
            .pipe(untilDestroyed(this))
            .subscribe(() => {
              this.notificationService
                .read(notificationGroup.NotificationGroupId)
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                  this.router.navigate(["/publisher/calendar"]).then();
                });
            });
        });
      });
    this.notificationService.notificationCountObservable
      .pipe(untilDestroyed(this))
      .subscribe((notificationCount: number) => {
        if (!this.title) {
          return;
        }
        if (notificationCount > 0) {
          this.titleService.setTitle(`(${notificationCount}) ${this.title}`);
        } else {
          this.titleService.setTitle(`${this.title}`);
        }
      });
  }

  private setTimezone() {
    this.userService
      .me()
      .pipe(untilDestroyed(this))
      .subscribe((user: User) => {
        moment.tz.setDefault(user.TimezoneId);
      });
  }

  private setCustomIcons() {
    Object.keys(CUSTOM_ICONS).forEach((key) => {
      this.matIconRegistry.addSvgIcon(
        key,
        this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/" + CUSTOM_ICONS[key] + ".svg"),
      );
    });
  }

  private getDefaultProfiles() {
    this.profileService.getDefaults().pipe(untilDestroyed(this)).subscribe();
  }

  ngAfterViewInit(): void {
    const startupLogo = this.renderer.selectRootElement("#startuplogo");
    this.renderer.setStyle(startupLogo, "display", "none");
  }
}
