import {Component, OnInit} from "@angular/core";
import {AuthenticationService} from "@auth/authentication.service";
import {ThemeService} from "@theme/theme.service";
import {UserService} from "@user/user.service";
import {NotificationPanelComponent} from "@notifications/notification-panel/notification-panel.component";
import {PanelOverlayService} from "@overlay-panel/panel-overlay.service";
import {ComposerComponent} from "@composer/composer.component";
import {NotificationService} from "@notifications/notification.service";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {UntilDestroy} from "@ngneat/until-destroy";
import {PublisherSettingsService} from "../../pages/publisher/publisherSettings.service";
import {ProfileService} from "@channel/profile.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@UntilDestroy()
@Component({
  selector: "app-layout",
  templateUrl: "./app-layout.component.html",
  styleUrls: ["./app-layout.component.scss"],
})
export class AppLayoutComponent implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    public userService: UserService,
    private panelOverlay: PanelOverlayService<NotificationPanelComponent>,
    private composerOverlay: PanelOverlayService<ComposerComponent>,
    public notificationService: NotificationService,
    private dialog: MatDialog,
    public themeService: ThemeService,
    public router: Router,
    private profileService: ProfileService,
    private snackBar: MatSnackBar,
    public publisherSettingsService: PublisherSettingsService,
  ) {}

  public anyProfileRequiresAttention = false;

  ngOnInit() {
    this.profileService.list().subscribe((profiles) => {
      this.anyProfileRequiresAttention = profiles.some((x) => x.Credential.RequiresAttention);
      if (this.anyProfileRequiresAttention) {
        const snackBarRef = this.snackBar.open("One or more channels require your attention.", "Check", {
          horizontalPosition: "center",
          verticalPosition: "top",
          duration: 60000,
        });
        snackBarRef.onAction().subscribe(() => this.goToChannels());
      }
    });
  }

  goToChannels() {
    this.router.navigate(["/settings/channels"]).then();
  }
}
