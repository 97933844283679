import {BrowserModule} from "@angular/platform-browser";
import {ErrorHandler, NgModule} from "@angular/core";
import {SharedModule} from "@shared/shared.module";
import {AppComponent} from "./app.component";
import {AppRoutingModule} from "./app-routing.module";
import {AppLayoutComponent} from "./layouts/app-layout/app-layout.component";
import {AuthLayoutComponent} from "./layouts/auth-layout/auth-layout.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {CommonModule} from "@angular/common";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AuthInterceptor} from "@auth/auth.interceptor";
import {MAT_SNACK_BAR_DEFAULT_OPTIONS} from "@angular/material/snack-bar";
import {QuillModule} from "ngx-quill";
import {CookieService} from "ngx-cookie-service";
import {PanelOverlayService} from "@overlay-panel/panel-overlay.service";
import {GlobalErrorHandler} from "@utils/globalErrorHandler.service";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";

@NgModule({
  declarations: [AppComponent, AuthLayoutComponent, AppLayoutComponent],
  imports: [
    BrowserModule.withServerTransition({appId: "ng-cli-universal"}),
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    QuillModule.forRoot(),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 3000,
        verticalPosition: "top",
      },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: "dynamic",
      },
    },
    CookieService,
    PanelOverlayService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
