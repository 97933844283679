import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router";
import {AuthenticationService} from "./authentication.service";

@Injectable({
  providedIn: "root",
})
export class LoggedGuard {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authService.isLogged()) {
      this.router.navigate(["/login"], {
        queryParams: {returnUrl: state.url},
      });
      return false;
    }
    return true;
  }
}
