import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {AppLayoutComponent} from "./layouts/app-layout/app-layout.component";
import {AuthLayoutComponent} from "./layouts/auth-layout/auth-layout.component";
import {LoggedGuard} from "@auth/logged.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/auth/login",
    pathMatch: "full",
  },
  {
    path: "",
    component: AppLayoutComponent,
    canActivate: [LoggedGuard],
    children: [
      {
        path: "streams",
        loadChildren: () => import("./pages/streams/streams.module").then((m) => m.StreamsModule),
        data: {
          title: "Streams",
        },
      },
      {
        path: "publisher",
        loadChildren: () => import("./pages/publisher/publisher.module").then((m) => m.PublisherModule),
        data: {
          title: "Publisher",
        },
      },
      {
        path: "user",
        loadChildren: () => import("./pages/user/user.module").then((m) => m.UserModule),
        data: {
          title: "Profile",
        },
      },
      {
        path: "settings",
        loadChildren: () => import("./pages/settings/settings.module").then((m) => m.SettingsModule),
        data: {
          title: "Settings",
        },
      },
      {
        path: "analytics",
        loadChildren: () => import("./pages/analytics/analytics.module").then((m) => m.AnalyticsModule),
        data: {
          title: "Analytics",
        },
      },
      {
        path: "admin",
        loadChildren: () => import("./pages/admin/admin.module").then((m) => m.AdminModule),
        data: {
          title: "Admin",
        },
      },
      {
        path: "user-guide",
        loadChildren: () => import("./pages/user-guide/user-guide.module").then((m) => m.UserGuideModule),
        data: {
          title: "User Guide",
        },
      },
    ],
  },
  {
    path: "auth",
    component: AuthLayoutComponent,
    loadChildren: () => import("./pages/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "user",
    component: AuthLayoutComponent,
    loadChildren: () => import("./pages/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "oauth",
    loadChildren: () => import("./pages/oauth/oauth.module").then((m) => m.OAuthModule),
    data: {
      title: "OAuth",
    },
  },
  {
    path: "sso",
    loadChildren: () => import("./pages/sso/sso-auth.module").then((m) => m.SsoAuthModule),
    data: {
      title: "SSO",
    },
  },
  {
    path: "teams-employee",
    loadChildren: () =>
      import("./pages/iframe/microsoft-teams/teams-stream/teams-stream.module").then((m) => m.TeamsStreamModule),
    data: {
      title: "SocialWeaver for Teams",
    },
  },
  {
    path: "ms-teams",
    loadChildren: () =>
      import("./pages/iframe/microsoft-teams/microsoft-teams.module").then((m) => m.MicrosoftTeamsModule),
    data: {
      title: "SocialWeaver for Teams",
    },
  },
  // Fallback when no prior routes is matched
  {path: "**", redirectTo: "/auth/login", pathMatch: "full"},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
