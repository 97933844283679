export const CUSTOM_ICONS = {
  ad: "ad",
  affiliate: "affiliate",
  "alert-circle": "alert-circle",
  "alexa-badge": "alexa-badge",
  "align-justified": "align-justified",
  americanexpress: "americanexpress",
  angry: "angry",
  analytics: "analytics",
  "analytics-solid": "analytics-solid",
  "apple-logo": "apple-logo",
  "arrow-bar-to-left": "arrow-bar-to-left",
  "arrow-bar-to-right": "arrow-bar-to-right",
  "arrow-left": "arrow-left",
  "arrow-right": "arrow-right",
  "arrow-up": "arrow-up",
  "arrows-sort": "arrows-sort",
  "auth-2fa": "auth-2fa",
  backspace: "backspace",
  book: "book",
  briefcase: "briefcase",
  calendar: "calendar",
  cash: "cash",
  channels: "channels",
  "channels-solid": "channels-solid",
  carousel: "carousel",
  "check-circle": "check-circle",
  "check-circle-outline": "check-circle-outline",
  "check-circle-solid": "check-circle-solid",
  check: "check",
  checks: "checks",
  "chevron-circle-left-solid": "chevron-circle-left-solid",
  "chevron-circle-right-solid": "chevron-circle-right-solid",
  "chevron-down": "chevron-down",
  "chevron-left": "chevron-left",
  "chevron-right": "chevron-right",
  "circle-plus": "circle-plus",
  "circle-solid": "circle-solid",
  clipboard: "clipboard",
  clock: "clock",
  collection: "collection",
  circle: "circle",
  compose: "compose",
  "compose-solid": "compose-solid",
  content: "content",
  conversations: "conversations",
  "credit-card": "credit-card",
  "crown-regular": "crown-regular",
  customize: "customize",
  dark: "dark",
  "dark-solid": "dark-solid",
  desktop: "desktop",
  discount: "discount",
  discover: "discover",
  "dots-vertical": "dots-vertical",
  "dropdown-arrow": "dropdown-arrow",
  dots: "dots",
  download: "download",
  "download-cloud": "download-cloud",
  droplet: "droplet",
  employees: "employees",
  "empty-set-regular": "empty-set-regular",
  error: "error",
  "exclamation-circle-solid": "exclamation-circle-solid",
  "exclamation-triangle-solid": "exclamation-triangle-solid",
  "external-link": "external-link",
  "eye-off": "eye-off",
  eye: "eye",
  "facebook-badge": "facebook-badge",
  "facebook-logo": "facebook-logo",
  facebook: "facebook",
  "file-export": "file-export",
  "file-invoice": "file-invoice",
  "filter-solid": "filter-solid",
  filter: "filter",
  fingerprint: "fingerprint",
  gauge: "gauge",
  gift: "gift",
  globe: "globe",
  "google-logo": "google-logo",
  google: "google",
  "grip-vertical-solid": "grip-vertical-solid",
  groups: "groups",
  haha: "haha",
  hashtag: "hashtag",
  "heart-solid": "heart-solid",
  heart: "heart",
  help: "help",
  home: "home",
  hourglass: "hourglass",
  inbox: "inbox",
  infinity: "infinity",
  info: "info",
  "info-circle": "info-circle",
  "info-circle-solid": "info-circle-solid",
  instagram: "instagram",
  "instagram-badge": "instagram-badge",
  "instagram-bookmark": "instagram-bookmark",
  "instagram-comment": "instagram-comment",
  "instagram-like": "instagram-like",
  "instagram-logo": "instagram-logo",
  "instagram-outline": "instagram-outline",
  "instagram-share": "instagram-share",
  key: "key",
  lifebuoy: "lifebuoy",
  light: "light",
  "light-solid": "light-solid",
  "like-solid": "like-solid",
  like: "like",
  link: "link",
  "link-tracking": "link-tracking",
  linkedin: "linkedin",
  "linkedin-badge": "linkedin-badge",
  "linkedin-comment": "linkedin-comment",
  "linkedin-like": "linkedin-like",
  "linkedin-logo": "linkedin-logo",
  "linkedin-send": "linkedin-send",
  "linkedin-share": "linkedin-share",
  lock: "lock",
  login: "login",
  logout: "logout",
  love: "love",
  mail: "mail",
  manual: "manual",
  mention: "mention",
  mastercard: "mastercard",
  menu: "menu",
  "menu-triple": "menu-triple",
  "message-circle": "message-circle",
  "message-square": "message-square",
  minus: "minus",
  "minus-circle": "minus-circle",
  mobile: "mobile",
  "moz-badge": "moz-badge",
  muted: "muted",
  "nav-linkedin": "nav-linkedin",
  new: "new",
  notifications: "notifications",
  "notifications-solid": "notifications-solid",
  photo: "photo",
  pinterest: "pinterest",
  "pinterest-badge": "pinterest-badge",
  "play-arrow-white": "play-arrow-white",
  "play-arrow": "play-arrow",
  "play-black": "play-black",
  "play-video": "play-video",
  "play-white": "play-white",
  play: "play",
  plus: "plus",
  "plus-square": "plus-square",
  power: "power",
  publisher: "publisher",
  "publisher-solid": "publisher-solid",
  puzzle: "puzzle",
  "question-mark": "question-mark",
  radioactive: "radioactive",
  refresh: "refresh",
  repeat: "repeat",
  return: "return",
  "rotate-clockwise": "rotate-clockwise",
  rss: "rss",
  "rss-badge": "rss-badge",
  sad: "sad",
  scissors: "scissors",
  search: "search",
  selector: "selector",
  "send-notification": "send-notification",
  settings: "settings",
  "share-arrow": "share-arrow",
  share: "share",
  shield: "shield",
  sidenav: "sidenav",
  slack: "slack",
  "slack-logo": "slack-logo",
  smiley: "smiley",
  socialweaver: "socialweaver",
  "sort-ascending": "sort-ascending",
  "sort-descending": "sort-descending",
  "square-x": "square-x",
  square: "square",
  streams: "streams",
  "streams-solid": "streams-solid",
  sync: "sync",
  tag: "tag",
  tags: "tags",
  teams: "teams",
  "teams-logo": "teams-logo",
  "text-caption": "text-caption",
  trash: "trash",
  tweet: "tweet",
  "twitter-badge": "twitter-badge",
  "twitter-like": "twitter-like",
  "twitter-logo": "twitter-logo",
  "twitter-outline": "twitter-outline",
  "twitter-verified": "twitter-verified",
  "twitter-reply": "twitter-reply",
  "twitter-retweet": "twitter-retweet",
  "twitter-share": "twitter-share",
  twitter: "twitter",
  "unknown-user": "unknown-user",
  upload: "upload",
  user: "user",
  "user-2": "user-2",
  "user-3": "user-3",
  users: "users",
  "users-2": "users-2",
  "users-3": "users-3",
  "users-group": "users-group",
  "users-plus": "users-plus",
  "user-group": "user-group",
  "video-file": "video-file",
  visa: "visa",
  volume: "volume",
  wow: "wow",
  x: "x",
  zapier: "zapier",
  "zapier-logo": "zapier-logo",
  "bitly-logo": "bitly-logo",
  "zoom-in": "zoom-in",
  "zoom-out": "zoom-out",
};
